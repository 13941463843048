<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-4">
            <h4 class="page-title">{{ $t(title) }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("warehouse.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("warehouse.expired_") }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <template>
              <div class="card">
                <AlertMessageComponent ref="alertMessageComponent" />
                <v-card-title>
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title mb-0">
                        <i class="feather icon-package mr-2"></i>
                        {{ $t("warehouse.title") }}
                      </h5>
                    </div>
                  </div>
                </v-card-title>
                <!-- Table de WareHouse -->
                <div class="row">
                  <div
                    class="
                      col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3
                      pl-6
                      pb-0
                      pr-6
                    "
                    v-if="withPermissionName(permissions.INVENTORY_DETAIL)"
                  >
                    <div class="card br">
                      <v-list-item two-line>
                        <v-list-item-action>
                          <v-switch v-model="switchDetails" inset></v-switch>
                        </v-list-item-action>
                        <v-list-item-content>
                          <h6 class="mb-0">
                            {{ $t("warehouse.existence_detail") }}
                          </h6>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <!--  <v-switch v-model="switchDetails" inset label="Detalle de Existencia"></v-switch> -->
                  </div>
                  <div
                    class="
                      col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3
                      pl-6
                      pr-6
                      pb-0
                    "
                    v-if="withPermissionName(permissions.WAR_INV_ASS_TAG_06)"
                  >
                    <div class="card br">
                      <v-list-item two-line>
                        <v-list-item-action>
                          <v-switch
                            v-model="switchSoldOut"
                            @click="refreshTables()"
                            inset
                          ></v-switch>
                        </v-list-item-action>
                        <v-list-item-content>
                          <h6 class="mb-0">
                            {{ $t("warehouse.products_sold_out") }}
                          </h6>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <WarehouseTableComponent
                      :switchList="switchList"
                      :switchHeader="switchHeader"
                      :searchTableMain="searchTableMain"
                      :switchDetails="switchDetails"
                      :switchSoldOut="switchSoldOut"
                      :showAction="showAction"
                      :viewItem="viewItem"
                      :changeQuantity="changeQuantity"
                      :moveItem="moveItem"
                      :edit="edit"
                      :index="index"
                      :isDetail="
                        withPermissionName(permissions.WAR_INV_PRO_TRA_01)
                      "
                      :updateLot="
                        withPermissionName(permissions.WAR_INV_EDIT_02)
                      "
                      :stockReAsing="
                        withPermissionName(permissions.WAR_REA_PRO_03)
                      "
                      :stockUpdateQuantify="
                        withPermissionName(permissions.WAR_INV_QUAN_EDIT_04)
                      "
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="p-0">
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="ti-stats-up mr-2"></i>
              {{ $t("warehouse.product_tracking") }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title mt-5">
                <i class="fa fa-inbox mr-2"></i
                >{{ $t("warehouse.inventory_detail") }} #
                {{ stockDetail.idStock }}
              </h5>
              <hr />
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_ref") }}</strong
                  ><br />
                  <i class="ti-package mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.remmisionKey }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_data") }}</strong
                  ><br />
                  <i class="fa fa-calendar mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.dateIn }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_data_remission") }}</strong
                  ><br />
                  <i class="fa fa-calendar mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.dateInputRemmision }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <strong>{{ $t("warehouse.sub_clave") }}</strong
                  ><br />
                  <i class="fa fa-file-o mr-2 text-fmedical-blue"></i>
                  {{ moveItemHolder.productKey }}
                </div>
                <div class="col-md-10" align="justify">
                  <strong>{{ $t("warehouse.sub_product") }}</strong
                  ><br />
                  <i class="fa fa-file-o mr-2 text-fmedical-blue"></i>
                  {{
                    moveItemHolder.productName +
                    " " +
                    moveItemHolder.productDescripcion
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <template>
              <div class="col-12">
                <v-tabs
                  class="br"
                  v-model="tab"
                  background-color="#263A5B"
                  centered
                  dark
                  icons-and-text
                >
                  <v-tab href="#1">
                    {{ $t("warehouse.sub_detail_moven") }}
                    <v-icon>fa fa-info-circle</v-icon>
                  </v-tab>
                  <v-tab href="#2">
                    {{ $t("warehouse.sub_detail_stock_origin") }}
                    <v-icon>fa fa-folder-open-o</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="1" eager>
                    <div
                      v-if="listFilterStatusDestinaction.length > 2"
                      class="col-md-12 col-sm-12 pb-0 pt-4"
                    >
                      <div class="row">
                        <div class="col-md-4 col-sm-12">
                          <v-select
                            v-model="filterStatusDestination"
                            dense
                            outlined
                            :items="listFilterStatusDestinaction"
                            menu-props="auto"
                            :label="$t('label.filter_status')"
                            hide-details
                          ></v-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 pt-0">
                      <StockDestinationList
                        ref="stockDestination"
                        :stockDestinationList="stockDestinationList"
                      />
                    </div>
                  </v-tab-item>
                  <v-tab-item value="2" eager>
                    <div class="col-md-12 col-sm-12">
                      <StockOriginList
                        ref="stockOrigin"
                        :stockOriginList="stockOriginList"
                      />
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEdit" persistent width="600px">
      <v-card>
        <v-card-title>
          <h5 class="card-title mb-0">
            <i class="ti-package mr-2"></i>
            {{ $t("warehouse.title_modify_product") }}
          </h5>
        </v-card-title>
        <validation-observer ref="observer" v-slot="{ invalid }" disabled>
          <v-container class="p-4">
            <div class="row">
              <div class="col-md-6">
                <vc-date-picker
                  scrollable
                  color="green"
                  v-model="model.expirationDate"
                  is-required
                  :update-on-input="false"
                  :model-config="modelConfig"
                >
                  <template v-slot="{ inputEvents }">
                    <div class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="expiry_date"
                        rules="required"
                      >
                        <v-text-field
                          v-on="inputEvents"
                          :error-messages="errors"
                          :value="model.expirationDate"
                          :readonly="true"
                          :label="$t('label.expiry_date')"
                          required
                        ></v-text-field>
                      </validation-provider>
                    </div>
                  </template>
                </vc-date-picker>
              </div>
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="lot"
                  rules="required|max:15|regex:^[0-9A-ZÁÉÍÑÓÚÜ]+(?:-[0-9A-ZÁÉÍÑÓÚÜ0-9]+)*$"
                >
                  <v-text-field
                    v-model="model.lote"
                    type="text"
                    :counter="15"
                    :maxlength="15"
                    :error-messages="errors"
                    :label="$t('label.lot')"
                    required
                    oninput="this.value = this.value.toUpperCase()"
                    autocomplete="off"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <v-textarea
                    v-model="model.fullName"
                    :disabled="true"
                    rows="2"
                    :label="$t('label.product')"
                    :error-messages="errors"
                    required
                  ></v-textarea>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Causes"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.productKey"
                    :disabled="true"
                    :label="$t('label.causes_')"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-md-6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Financiamiento"
                  rules="required"
                >
                  <v-text-field
                    v-model="model.fiscalFund"
                    :disabled="true"
                    :label="$t('label.financing')"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </v-container>
          <br />
          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="col-md-12 text-right">
              <button
                class="btn btn-outline-success mr-5"
                @click.prevent="update()"
                :disabled="invalid || sending"
              >
                <i class="feather icon-save mr-1"></i> {{ $t("global.update") }}
              </button>
              <button
                class="btn btn-outline-danger"
                @click="dialogEdit = false"
              >
                <i class="feather icon-x mr-1 mr-2"></i> {{ $t("global.cancel") }}
              </button>
            </div>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMove" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <h5 class="card-title mb-0">
            <i class="ti-package mr-2"></i>
            {{ $t("warehouse.title_moved_product") }}
          </h5>
        </v-card-title>
        <validation-observer ref="obs1" v-slot="{ invalid }">
          <v-card-text class="p-1">
            <v-container>
              <AlertMessageComponent ref="alertdialogMove" />
              <v-col class="p-0" cols="12" sm="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="location_warehouse"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    :label="$t('label.location_warehouse')"
                    :error-messages="errors"
                    v-model="newLocation"
                    :counter="200"
                    :maxlength="200"
                    @change="checkBtn"
                    oninput="this.value = this.value.toUpperCase()"
                  >
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-outline-success mr-2"
              :disabled="movebtn || invalid"
              @click="saveNewLocation"
            >
              <i class="ti-package mr-2"></i> {{ $t("global.move") }}
            </button>
            <button class="btn btn-outline-danger" @click="cancelMove()">
              <i class="feather icon-x mr-1 mr-2"></i> {{ $t("global.cancel") }}
            </button>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogQuantity"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="ti-package mr-2"></i>
              {{ $t("warehouse.title_card_quantity") }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialogQuantity = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <div class="col-md-12 pt-3">
              <div class="card p-3">
                <p class="pt-3">
                  <i class="ti-package mr-2 text-fmedical-blue"></i>
                  <strong>
                    <span> {{ $t("warehouse.causes") }}</span>
                  </strong>
                  {{ product.productKey }}
                </p>
                <p align="justify" class="pb-3">
                  <i class="ti-align-center mr-2 text-fmedical-blue"></i>
                  <strong>
                    <span> {{ $t("warehouse.description") }} </span>
                  </strong>
                  {{ product.completeDescription }}
                </p>
                <hr />
              </div>
            </div>
            <AlertMessageComponent ref="alertForm" />
            <div class="col-md-12">
              <v-data-table
                dense
                :headers="stockProductHeader"
                :items="stockProduct"
                height="auto"
                fixed-header
                :footer-props="{
                  'items-per-page-text': $t('table.item_table'),
                  'items-per-page-all-text': $t('table.all'),
                  'items-per-page-options': [15, 30, 50, 100],
                }"
                :loading-text="$t('table.loading')"
                :no-data-text="$t('table.no_results_available')"
                :no-results-text="$t('table.no_results_search')"
                :search="searchStockProduct"
              >
                <template v-slot:top>
                  <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <v-text-field
                        v-model="searchStockProduct"
                        :label="$t('table.search')"
                        class="pr-5"
                      ></v-text-field>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <form @submit.prevent="updateStockByProductQuantity(item)">
                      <div class="row d-flex align-content-center flex-wrap">
                        <div
                          class="
                            col-md-8
                            pt-8
                            pr-0
                            d-flex
                            align-content-center
                            flex-wrap
                          "
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="quantity"
                          >
                            <v-text-field
                              outlined
                              dense
                              :disabled="sending"
                              v-model="item.quantityNew"
                              :error-messages="errors"
                              :label="$t('label.quantity')"
                              type="number"
                              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                              name="quantity"
                            ></v-text-field>
                          </validation-provider>
                        </div>
                        <div
                          class="col-md-4 d-flex align-content-center flex-wrap"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                type="submit"
                                :disabled="sending || invalid"
                                dark
                                small
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <i class="ti-package"></i>
                              </v-btn>
                            </template>
                            <span>{{ $t("table.edit_quantity") }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </form>
                  </validation-observer>
                </template>
              </v-data-table>
              <ButtonPrintComponent />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import WarehouseTableComponent from "@/components/warehouse/WarehouseTableComponent";
import StockDestinationList from "@/components/warehouse/StockDestinationList";
import StockOriginList from "@/components/warehouse/StockOriginList";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import { UtilFront, PermissionConstants } from "@/core";
import {
  WarehouseRequest,
  ExcelRequest,
  StockRequest,
  ProductResquest,
  RemissionRequest,
} from "@/core/request";
import moment from "moment";
// Vuex implementación
import { mapState, mapMutations } from "vuex";
import {
  STOCK,
  PRODUCT_STOCK,
  STOCK_BY_PRODUCT,
} from "@/core/DataTableHeadersLocale";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "warehouse.warehouse",
      search: "",
      mainStockList: [],
      objectResponse: {},
      currentItem: {},
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      tab: 0,
      /* Cambios de Stock */
      mainProductStockHeader: PRODUCT_STOCK(),
      stockProductHeader: STOCK_BY_PRODUCT(),

      dialogQuantity: false,
      searchTableMain: "",
      searchTableProducts: "",
      searchStockProduct: "",
      mainProductStockList: [],
      stockProduct: [],
      currentIdProduct: 0,
      currentProduct: "",
      currentProductDescription: "",
      sending: false,
      date: "",
      dateModel: {
        startDate: "",
        endDate: "",
      },
      model: {},
      index: 0,
      dialog: false,
      dialogMovEx: false,
      dialogMove: false,
      dialogEdit: false,
      editedIndex: -1,
      newQuantity: 0,
      newLocation: "",
      temQuantity: 0,
      movebtn: true,
      moveItemHolder: {
        idStock: "",
        productName: "",
        locationKey: "",
        quantity: "",
      },
      updateStockQuantity: {
        idStock: "",
        quantity: 0,
        branchOfficeId: "",
        userId: "",
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      stockOriginList: [],
      stockDestinationList: [],
      stockOriginListFilter: [],
      stockDestinationListFilter: [],
      /* Filtros */
      listFilterStatusDestinaction: [],
      listFilterStatusOrigin: [],
      filterStatusDestination: "",

      stockDetail: [],
      branchOffice: {},
      branchOffices: [],
      action: 1,
      permissions: PermissionConstants,
      /*Cuando se envia*/
      sending: false,
      loading: null,

      /* Global */
      product: {},

      /* Detalle de Existencia (Cambio de stock) */
      mainStockHeader: STOCK(),
      level: "",
      switchDetails: true,
      switchSoldOut: false,
      expired: false,
      today: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    checkQuantity() {
      return `required|min_value:1|max_value:${
        parseInt(this.temQuantity) + parseInt(this.moveItemHolder.quantity)
      }`;
    },
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    switchHeader() {
      return this.switchDetails ? STOCK() : PRODUCT_STOCK();
    },
    switchList() {
      return this.switchDetails
        ? this.mainStockList
        : this.mainProductStockList;
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    cancelMove() {
      this.dialogMove = false;
      this.$refs.obs1.reset();
      this.$refs.alertdialogMove.reloadComponent(0);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
        this.dialogEdit = false;
      } else {
        this.action = action;
      }
    },
    async viewItem(item) {
      try {
        this.tab = 1;
        this.editedIndex = this.mainStockList.indexOf(item);
        this.moveItemHolder = Object.assign({}, item);
        this.loading = UtilFront.getLoadding();
        this.dialog = true;
        await this.getStockHistory(this.moveItemHolder.idStock);
        this.$refs.stockDestination.reloadComponent(this.stockDestinationList);
        this.$refs.stockOrigin.reloadComponent(this.stockOriginList);
      } catch (error) {
        this.$toast.error("Algo salio mal; Intenta de nuevo.");
        this.$refs.stockDestination.reloadComponent([]);
        this.$refs.stockOrigin.reloadComponent([]);
        this.dialog = false;
      } finally {
        this.loading.hide();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async saveNewLocation() {
      this.movebtn = true;
      await WarehouseRequest.moveStockLocation({
        idStock: this.moveItemHolder.idStock,
        userId: UtilFront.getDataUser().idUser,
        locationkey: this.newLocation,
      })
        .then((response) => {
          if (response.data.data.success) {
            this.newQuantity = 0;
            this.temQuantity = 0;
            this.newLocation = "";
            this.alert("success", "Se movio el producto a una nueva ubicación");
            this.listBranchOfficeByUser();
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", "internal");
        })
        .finally(() => {
          this.dialogMove = false;
          this.movebtn = false;
        });
    },
    moveItem(item) {
      this.dialogMove = true;
      this.editedIndex = this.mainStockList.indexOf(item);
      this.moveItemHolder = {
        ...this.moveItemHolder,
        ...item,
      };
      this.newQuantity = null;
      this.temQuantity = 0;
      this.newLocation = "";
    },
    updateQuantities() {
      let suma =
        parseInt(this.temQuantity) + parseInt(this.moveItemHolder.quantity);
      if (this.newQuantity > suma) {
        this.$refs.alertdialogMove.reloadComponent(
          "error",
          "La cantidad que deseas mover es mayor a la existencia."
        );
        this.movebtn = true;
      } else {
        this.$refs.alertdialogMove.reloadComponent(0);
        this.moveItemHolder.quantity =
          this.moveItemHolder.quantity + this.temQuantity;
        this.moveItemHolder.quantity =
          this.moveItemHolder.quantity - this.newQuantity;
        this.temQuantity = this.newQuantity;
        this.movebtn = false;
      }
    },
    updateQuantity() {
      this.movebtn = true;
    },
    checkBtn() {
      let suma =
        parseInt(this.temQuantity) + parseInt(this.moveItemHolder.quantity);
      if (this.newQuantity <= suma && this.newLocation != "") {
        this.movebtn = false;
      }
    },
    async getStockHistory(idStock) {
      await WarehouseRequest.stockHistoryOrigin({
        id: idStock,
      })
        .then((response) => {
          this.stockOriginList = response.data.data.objects;
          this.stockOriginListFilter = response.data.data.objects;
          this.stockOriginListFilter.forEach((data) => {
            let listStatus = {
              value: data.statusNumber,
              text: data.status,
            };
            this.listFilterStatusOrigin.push(listStatus);
          });
          let hash = {};
          this.listFilterStatusOrigin = this.listFilterStatusOrigin.filter(
            (data) => (hash[data.value] ? false : (hash[data.value] = true))
          );
          this.listFilterStatusOrigin = [
            ...this.listFilterStatusOrigin,
            {
              value: "",
              text: "Sin filtro",
            },
          ];
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});

      await WarehouseRequest.stockHistoryDestination({
        id: idStock,
      })
        .then((response) => {
          this.stockDestinationList = response.data.data.objects;
          this.stockDestinationListFilter = response.data.data.objects;
          this.listFilterStatusDestinaction = [];
          this.stockDestinationListFilter.forEach((data) => {
            let listStatus = {
              value: data.statusNumber,
              text: data.status,
            };
            this.listFilterStatusDestinaction.push(listStatus);
          });
          let hash = {};
          this.listFilterStatusDestinaction =
            this.listFilterStatusDestinaction.filter((data) =>
              hash[data.value] ? false : (hash[data.value] = true)
            );
          this.listFilterStatusDestinaction = [
            ...this.listFilterStatusDestinaction,
            {
              value: "",
              text: "Sin filtro",
            },
          ];
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
      await WarehouseRequest.stockDetail({
        id: idStock,
      })
        .then((response) => {
          this.stockDetail = response.data.data.object;
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
      this.filterStatusDestination = "";
    },
    async listAll() {
      await WarehouseRequest.getAllStockFromUserBranch({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
        soldOut: this.switchSoldOut,
      })
        .then((response) => {
          this.mainStockList = [];
          for (var [index, value] of Object.entries(
            response.data.data.objects
          )) {

            let date = value.expirationDate;
            let parsed = moment(date, "DD/MM/YYYY");
            let valid = moment(this.today).isSameOrBefore(parsed) ? moment.duration(moment(parsed).diff(this.today))
                         :'Caducado';

            let mainStockitem = {
              fiscalFund: value.fiscalFund,
              idProduct: value.idProduct,
              idStock: value.idStock,
              locationKey: value.locationKey,
              lote: value.lote,
              productDescripcion: value.productDescripcion,
              productKey: value.productKey,
              productName: value.productName + " " + value.productDescripcion,
              quantity: value.quantity,
              status: value.status,
              expirationDate: value.expirationDate,
              idProductRemmision: value.idProductRemmision,
              remissionType: value.remissionType,
              validDate: valid
            };
            this.mainStockList.push(mainStockitem);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    async listAllExpired() {
      await WarehouseRequest.getAllStockFromUserBranchExpired({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
        soldOut: this.switchSoldOut,
      })
        .then((response) => {
          this.mainStockList = [];
          for (var [index, value] of Object.entries(
            response.data.data.objects
          )) {
            let mainStockitem = {
              fiscalFund: value.fiscalFund,
              idProduct: value.idProduct,
              idStock: value.idStock,
              locationKey: value.locationKey,
              lote: value.lote,
              productDescripcion: value.productDescripcion,
              productKey: value.productKey,
              productName: value.productName + " " + value.productDescripcion,
              quantity: value.quantity,
              status: value.status,
              expirationDate: value.expirationDate,
              idProductRemmision: value.idProductRemmision,
              remissionType: value.remissionType,
            };
            this.mainStockList.push(mainStockitem);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    async listAllProductsExpired() {
      this.mainProductStockList = [];
      await ProductResquest.findAllProductsInStockByBranchOfficeExpired({
        soldOut: this.switchSoldOut,
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      }).then((response) => {
        let { data, message, success } = response.data.data;
        if (success) {
          this.mainProductStockList = data.objects;
        }
      });
    },
    async listBranchOfficeByUser() {
      try {
        this.branchOffice = this.branchOfficeState;
        this.updateAction(1);
        this.loading = UtilFront.getLoadding();
        switch (this.$route.path) {
          case "/almacen":
            await this.listAll();
            await this.listAllProducts();
            break;
          case "/caducados":
            await this.listAllExpired();
            await this.listAllProductsExpired();
            break;
          default:
        }
        this.loading.hide();
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading.hide();
      }
    },
    excelWareHouse() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportWareHouse({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice.idBranchoffice,
      });
    },
    edit(object) {
      this.dialogEdit = true;
      this.model = {
        ...this.model,
        ...object,
      };
      this.currentItem = object;
      this.model.fullName = `${this.model.productName} ${this.model.productDescripcion}`;
      //this.changeAction(3);
    },
    update() {
      //Solo envio la informacion que sea necesaria
      let modelUpdate = {
        idProductRemmision: this.currentItem.idProductRemmision,
        productId: this.currentItem.idProduct,
        userBranchOffice: {
          idUser: UtilFront.getIdUser(),
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      };
      //Caducidad
      this.currentItem.expirationDate != this.model.expirationDate
        ? (modelUpdate.expirationDate = moment(
            this.model.expirationDate,
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD"))
        : null;
      //Lotes
      this.currentItem.lote != this.model.lote
        ? (modelUpdate.lot = this.model.lote)
        : null;
      //Muestro en carga a los elementos
      this.sending = true;
      this.loading = UtilFront.getLoadding();
      RemissionRequest.updateProductRemmision(modelUpdate)
        .then((response) => {
          let { success } = response.data.data;
          if (success) {
            this.$toast.success("Remisión del Producto Actualizado.");
            //Actualizo la información visible
            this.currentItem.expirationDate = this.model.expirationDate;
            this.currentItem.lote = this.model.lote;
          } else {
            this.$toast.error(
              "No fue posible actualizar la información stock."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.sending = false;
          this.loading.hide();
          this.dialogEdit = false;
        });
    },
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    updateStockProduct() {
      StockRequest.listAllStockByProductAndBranchOffice({
        userId: UtilFront.getDataUser().idUser,
        branchOfficeId: this.branchOffice.idBranchoffice,
        productId: this.currentIdProduct,
        soldOut: this.switchSoldOut,
      })
        .then((response) => {
          let { data } = response.data;
          this.stockProduct = data.objects;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.dialogEdit = false;
        });
    },
    async listAllProducts() {
      this.mainProductStockList = [];
      await ProductResquest.findAllProductsInStockByBranchOffice({
        soldOut: this.switchSoldOut,
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice.idBranchoffice,
        },
      }).then((response) => {
        let { data } = response.data.data;
        this.mainProductStockList = data;
      });
    },
    async refreshTables() {
      try {
        this.loading = UtilFront.getLoadding();
        switch (this.$route.path) {
          case "/almacen":
            await this.listAll();
            await this.listAllProducts();
            break;
          case "/caducados":
            await this.listAllExpired();
            await this.listAllProductsExpired();
            break;
          default:
        }
        this.loading.hide();
      } catch (error) {
        this.loading.hide();
      }
    },
    updateStockByProductQuantity(item) {
      if (item.quantityNew != null) {
        //Procesando
        this.sending = true;
        this.updateStockQuantity.quantity = item.quantityNew;
        this.updateStockQuantity.idStock = item.idStock;
        this.loading = UtilFront.getLoadding();
        StockRequest.updateStockQuantity(this.updateStockQuantity)
          .then((response) => {
            let { data } = response.data;
            let { success, message } = data;
            if (success) {
              this.updateStockQuantity.quantity = 0;
              this.updateStockQuantity.idStock = "";
              this.moveItemHolder.quantity = "";
              this.$refs.alertForm.reloadComponent(
                "success",
                "Actualización de existencia realizada correctamente."
              );
              this.updateStockProduct();
            } else {
              this.$refs.alertForm.reloadComponent("error", message);
            }
            this.sending = false;
          })
          .catch((error) => {
            console.log(error);
            this.alert("internal");
          })
          .finally(() => {
            this.loading.hide();
            this.refreshTables();
          });
      } else {
        this.$refs.alertForm.reloadComponent(
          "error",
          "El campo cantidad es obligatorio."
        );
      }
    },
    changeQuantity(item, level) {
      this.dialogQuantity = true;
      //Guarda si es transferencia o actualizar cantidad en stock
      this.level = level;

      /* Información del producto */
      this.product = item;
      this.currentProduct = item.productKey;
      this.currentProductDescription = item.productDescripcion;
      this.currentIdProduct = item.idProduct;
      this.updateStockProduct();
      this.updateStockQuantity.branchOfficeId =
        this.branchOffice.idBranchoffice;
      this.updateStockQuantity.userId = UtilFront.getDataUser().idUser;
    },
  },
  async created() {
    this.expired = this.$route.path === "/almacen" ? false : true;
    await this.listBranchOfficeByUser();
  },
  watch: {
    filterStatusDestination() {
      if (this.filterStatusDestination == "") {
        this.stockDestinationList = this.stockDestinationListFilter;
      } else {
        this.stockDestinationList = this.stockDestinationListFilter.filter(
          (element) => {
            return element.statusNumber == this.filterStatusDestination;
          }
        );
      }
      this.$refs.stockDestination.reloadComponent(this.stockDestinationList);
    },
    async branchOfficeState() {
      await this.listBranchOfficeByUser();
    },
    "$i18n.locale": function () {
      this.mainStockHeader = STOCK();
      this.stockProductHeader = STOCK_BY_PRODUCT();
    },
    $route(to, from) {
      this.expired = this.$route.path === "/almacen" ? false : true;
      this.listBranchOfficeByUser();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    WarehouseTableComponent,
    StockDestinationList,
    StockOriginList,
    AlertMessageComponent,
    ButtonPrintComponent,
  },
};
</script>

<style scoped>
.blue-fmedical {
  background-color: #263a5b !important;
  color: white !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.br {
  border-radius: 8px !important;
}
</style>
